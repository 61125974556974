// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actividades-js": () => import("./../../../src/pages/actividades.js" /* webpackChunkName: "component---src-pages-actividades-js" */),
  "component---src-pages-activistas-guatemala-js": () => import("./../../../src/pages/activistas-guatemala.js" /* webpackChunkName: "component---src-pages-activistas-guatemala-js" */),
  "component---src-pages-activistas-honduras-js": () => import("./../../../src/pages/activistas-honduras.js" /* webpackChunkName: "component---src-pages-activistas-honduras-js" */),
  "component---src-pages-espacio-interactivo-js": () => import("./../../../src/pages/espacio-interactivo.js" /* webpackChunkName: "component---src-pages-espacio-interactivo-js" */),
  "component---src-pages-exposicion-js": () => import("./../../../src/pages/exposicion.js" /* webpackChunkName: "component---src-pages-exposicion-js" */),
  "component---src-pages-formulario-js": () => import("./../../../src/pages/formulario.js" /* webpackChunkName: "component---src-pages-formulario-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-templates-activist-js": () => import("./../../../src/templates/activist.js" /* webpackChunkName: "component---src-templates-activist-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-department-js": () => import("./../../../src/templates/department.js" /* webpackChunkName: "component---src-templates-department-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-pages-interactive-space-js": () => import("./../../../src/templates/pages-interactive-space.js" /* webpackChunkName: "component---src-templates-pages-interactive-space-js" */)
}

