import { lighten, darken } from "@theme-ui/color"
import "typeface-archivo-narrow"

const theme = {
  space: [
    0,
    `0.25rem`,
    `0.5rem`,
    `1rem`,
    `2rem`,
    `4rem`,
    `6rem`,
    `8rem`,
    `10rem`,
    `12rem`,
    `16rem`,
  ],
  fonts: {
    body: "\"Archivo Narrow\", system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", sans-serif",
    heading: "\"Archivo Narrow\", system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", sans-serif",
    monospace: "Menlo, monospace"
  },
  fontSizes: [
    `0.8rem`,
    `1rem`,
    `1.25rem`,
    `1.563rem`,
    `1.953rem`,
    `2.441rem`,
    `3.052rem`,
    `4.768rem`,
    `5.96rem`,
    `9.313rem`,
    `11.642rem`,
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  letterSpacings: [
    0,
    `0.5px`,
    `1px`,
  ],
  lineHeights: {
    body: 1.5,
    heading: 1,
    none: 1,
    snug: 1.33,
    relaxed: 1.625,
  },
  colors: {
    text: `#ffffff`,
    background: `#2B3A46`, //azul oscuro general 
    backgroundmaps: `#5d7c94`, //azul metalico sucio, barra lateral
    primary: `#496275`,
    primarydarken: darken(`primary`, 0.06),
    primarylight: lighten(`primary`, 0.4),
    primarylightest: lighten(`primary`, 0.2),
    secondary: `#4c3d52`, //morado
    tertiary: `#fac963`, //amarillo claro
    guatemala: `#F99F47`, //amarillo naranja
    guatemala1: `#13a576`, //verde
    honduras: `#fac963`, //amarillo claro == tertiary
    honduras1: `#d92e63`, //rojo
    muted: `#CFCFCF`,
    white: `#ffffff`,
    black: `#000000`,
    gray: [`#f8f8f8`, `#eaeaea`, `#d5d5d5`, `#CFCFCF`, `#b1b1b1`, `#585858`, `#1d1d1d`],
  },
  breakpoints: [
    `640px`,
    `769px`,
    `1025px`,
    `1281px`,
    `1640px`,
  ],
  sizes: {
    px: `1px`,
    "0": `0`,
    "1": `0.25rem`,
    "2": `0.5rem`,
    "3": `0.75rem`,
    "4": `1rem`,
    "5": `1.25rem`,
    "6": `1.5rem`,
    "8": `2rem`,
    "10": `2.5rem`,
    "12": `3rem`,
    "16": `4rem`,
    "20": `5rem`,
    "24": `6rem`,
    "32": `8rem`,
    "40": `10rem`,
    "48": `12rem`,
    "56": `14rem`,
    full: `100%`,
    screenHeight: `100vh`,
    screenWidth: `100vw`,
    phoneMaxWidht: `85vw`,
    tabletMaxWidth: `80vw`,
    desktopMaxWidth: `60vw`,
    desktopLargeMaxWidth: `45vw`,
  },
  forms: {
    input: {
      borderRadius: 0,
      border: `1px solid black`,
    },
    textarea: {
      borderRadius: 0,
    },
    label: {
      fontFamily: `body`,
    }
  },
  buttons: {
    primary: {
      color: `black`,
      border: `none`,
      borderRadius: `5px`,
      backgroundColor: `tertiary`,
      cursor: `pointer`,
      fontSize: 0,
      p: 3,
    },
    secondary: {
      color: `white`,
      border: `none`,
      borderRadius: `5px`,
      backgroundColor: `black`,
      cursor: `pointer`,
      fontSize: 0,
      fontWeight: `bold`,
      p: 3,
    },
  },
  layout: {
  // Viene de breakpoint theme-ui
  // Container tiene 6 valores
  // Estan los 5 valores de breakpoints + uno por defecto (el primero)
  // Por lo tanto puedo declarar:
  // maxWidth: [`100%`, `50%`, `45%`, `30%`, `23%`, `15%`],
  // Donde:
  // minWidth 100%
  // minWidth 640px 50% 
  // minWidth 769px 45%
  // minWidth 1025px 30%
  // minWidth 1281px 23%
  // minWidth 1640px 15%
    container: {
      maxWidth: [`phoneMaxWidht`, `phoneMaxWidht`, `tabletMaxWidth`, `desktopMaxWidth`, `desktopMaxWidth`, `desktopLargeMaxWidth`],
    },
  },
  text: {
    heading: {
      color: lighten(`primary`, 0.4),
      fontFamily: `heading`,
      fontWeight: `heading`,
      lineHeight: `heading`,
    },
    base: {
      color: `text`,
      fontFamily: `body`,
      fontSize: [1, 1, 1, 1, 1, 2],
      fontWeight: `body`,
      lineHeight: `snug`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
    },
  },
  styles: {
    root: {
      boxSizing: `border-box`,
      padding: 0,
      position: `relative`,
      ".tl-edges": {
        overflowY: `hidden`,
      },
      a: {
        textDecoration: `none`,
      }
    },
    a: {
      borderBottom: `1px dotted`,
      color: `white`,
      cursor: `pointer`,
      fontFamily: `body`,
      textDecoration: `none`,
      transition: `all 0.3s ease-in-out`,
      ":hover": {
        borderBottom: `1px solid`,
        color: `tertiary`,
      },
    },
    audio: {
      width: `100%`,
    },
    blockquote: {
      borderLeftColor: `white`,
      borderLeftStyle: `solid`,
      borderLeftWidth: `1px`,
      fontFamily: `body`,
      fontSize: [2, 2, 2, 3, 3, 3],
      marginLeft: 0,
      paddingLeft: 3,
      ":before": {
        content: `"“"`,
      },
      ":after": {
        content: `"”"`,
      },
    },
    caption: {
      textAlign: `left`
    },
    code: {
      fontFamily: `monospace`,
      fontSize: `inherit`
    },
    h1: {
      variant: `text.heading`,
      fontSize: [3, 3, 3, 6, 6, 6],
      mt: 1,
      mb: 1,
    },
    h2: {
      variant: `text.heading`,
      fontSize: [2, 2, 2, 4, 4, 4],
      marginTop: [2, 2, 2, 3, 3, 3],
      marginBottom: [2, 2, 2, 3, 3, 3],
    },
    h3: {
      variant: `text.heading`,
      fontSize: [1, 1, 1, 3, 3, 3],
      marginTop: [2, 2, 2, 3, 3, 3],
      marginBottom: [2, 2, 2, 3, 3, 3],
    },
    h4: {
      variant: `text.heading`,
      fontSize: [1, 1, 1, 2, 2, 2],
    },
    h5: {
      variant: `text.heading`,
      fontSize: 1,
    },
    h6: {
      variant: `text.heading`,
      fontSize: 1,
    },
    hr: {
      bg: `muted`,
      border: 0,
      height: `1px`,
      my: 3,
      mx: 0,
    },
    img: {
      maxWidth: `100%`,
    },
    p: {
      variant: `text.base`,
      marginBottom: [3, 3, 3, 3, 4, 4],
    },
    pre: {
      fontFamily: `monospace`,
      overflowX: `auto`,
      code: {
        color: `inherit`
      }
    },
    table: {
      borderCollapse: `separate`,
      borderSpacing: 0,
      borderTop: theme => `1px solid ${theme.colors.gray[4]}`,
      borderLeft: theme => `1px solid ${theme.colors.gray[4]}`,
      color: `text`,
      fontFamily: `body`,
      fontSize: 1,
      fontWeight: `body`,
      lineHeight: `snug`,
      marginBottom: 4,
      width: `100%`,
    },
    thead: {
      backgroundColor: theme => `${theme.colors.gray[1]}`,
    },
    th: {
      textAlign: `left`,
      borderBottom: theme => `1px solid ${theme.colors.gray[4]}`,
      borderRight: theme => `1px solid ${theme.colors.gray[4]}`,
      padding: 2,
    },
    td: {
      textAlign: `left`,
      borderBottom: theme => `1px solid ${theme.colors.gray[4]}`,
      borderRight: theme => `1px solid ${theme.colors.gray[4]}`,
      padding: 2,
    },
    ul: {
      fontFamily: `body`,
      listStyle: `circle`,
      marginLeft: 2,
      paddingLeft: 2,
      marginBottom: [3, 3, 3, 3, 3, 4],
    },
    li: {
      fontFamily: `body`,
      fontSize: [1, 1, 1, 1, 1, 2],
      my: [0, 0, 0, 2, 3, 3],
    },
    svg: {
      verticalAlign: `middle`,
    },
  }

}

export default theme